<template>
    <div class="home-certification">
        <div class="home-certification-container">
            <h3 class="block-title home-certification-ani poppins home-certification-title">{{ i18nPage.title }}</h3>
            <div class="home-certification-list">
                <div class="home-certification-ani home-certification-item" v-for="(item, index) in lists" :key="index">
                    <img :src="item" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('aboutusInfoPage.certification');
        },
        lists() {
            return [
                require('@assets/images/home/Bluetooth.png'),
                // require('@assets/images/home/fda.png'),
                require('@assets/images/home/kc-logo.png'),
                require('@assets/images/home/CNAS.png'),
                require('@assets/images/home/SRRC.png'),
                require('@assets/images/home/ma.png'),
                // require('@assets/images/home/ccc.png'),
                require('@assets/images/home/ce-logo.png'),
                require('@assets/images/home/fc.png'),
                require('@assets/images/home/sgs.png'),
                require('@assets/images/home/telec.png'),
            ];
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.home-certification-ani',
            triggerElement: '.home-certification',
        });
    },
};
</script>

<style lang="scss" scoped>
.home-certification {
    background: #f4f4f4;
    img {
        display: block;
    }
    @media only screen and (min-width: 769px) {
        &-container {
            max-width: 1120px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-list {
            max-width: 900px;
            margin: 40px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
        &-item {
            margin: 20px 30px 22px;
        }
    }
    @media only screen and (max-width: 768px) {
        &-container {
            padding: 0.6rem 0;
        }
        &-title {
            padding: 0 0.24rem;
            font-size: 0.26rem;
            text-align: center;
        }
        &-list {
            padding: 25px;
            display: flex;
            flex-flow: wrap;
            align-items: center;
            // justify-content: center;
            align-content: flex-start;
        }
        &-item {
            flex: 0 0 33%;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 120px;
        }
    }
}
</style>
