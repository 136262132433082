<template>
    <div class="home-custom">
        <div class="home-custom-container">
            <h2 class="poppins home-custom-ani block-title home-custom-title">{{ title }}</h2>
            <div class="home-custom-list">
                <div class="home-custom-scroll">
                    <div class="home-custom-ani home-custom-item" v-for="(item, index) in list" :key="index">
                        <img class="home-custom-item-icon" :src="require(`@assets/images/home/custom/${index + 1}.svg`)" alt="" />
                        <h3 class="poppins home-custom-item-title" :class="[currentLang]">{{ item.title }}</h3>
                        <div class="home-custom-item-desc">{{ item.desc }}</div>
                    </div>
                    <div class="placeholder"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        list: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.mxAnimation({
            handle: '.home-custom-ani',
            triggerElement: '.home-custom-container',
        });
    },
};
</script>

<style lang="scss">
.home-custom {
    background-color: #f9f9f9;

    @media only screen and (min-width: 769px) {
        &-container {
            width: 960px;
            margin: 0 auto;
            padding: 80px 0;
        }
        &-title {
            padding-bottom: 40px;
        }
        &-scroll {
            display: flex;
            justify-content: space-between;

            .placeholder {
                display: none;
            }
        }
        &-item {
            background: #fff;
            width: 225px;
            border-radius: 20px;
            flex-shrink: 0;
            padding: 30px;
            box-sizing: border-box;

            &-icon {
                display: block;
                margin-bottom: 10px;
            }

            &-title {
                font-size: 24px;
                line-height: 28.8px;
                font-weight: 500;
                margin-bottom: 10px;

                &.zh,
                &.tw {
                    font-weight: 600;
                }
            }
            &-desc {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        &-container {
            padding-top: 0.6rem;
        }
        &-title {
            padding: 0 0.35rem;
            margin-bottom: 0.25rem;
        }
        &-scroll {
            display: flex;
            flex-wrap: nowrap;
            overflow-y: auto;
            padding-bottom: 0.6rem;

            .placeholder {
                width: 0.35rem;
                flex-shrink: 0;
            }
        }
        &-item {
            flex-shrink: 0;
            width: 2.15rem;
            margin-left: 0.25rem;
            background: #fff;
            border-radius: 0.12rem;
            padding: 0.3rem;
            box-sizing: border-box;

            &:first-of-type {
                margin-left: 0.35rem;
            }

            &-icon {
                width: 0.45rem;
                height: 0.45rem;
                display: block;
                margin-bottom: 0.12rem;
            }
            &-title {
                font-size: 0.2rem;
                font-weight: 600;
                line-height: 1.1;
                margin-bottom: 0.12rem;
            }
            &-desc {
                font-size: 0.13rem;
                font-weight: 400;
            }
        }
    }
}
</style>
