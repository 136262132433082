<template>
    <div class="mt-patent">
        <div class="block-title mt-patent-ani poppins mt-patent-title">{{ i18nPage.title }}</div>
        <div class="block-desc mt-patent-ani mt-patent-desc">{{ i18nPage.desc }}</div>

        <swiper v-if="showSwiper" class="mt-patent-ani mt-patent-swiper" :options="swiperOption" ref="patentSwiper">
            <swiper-slide v-for="(item, index) in lists" :key="`slide_${index}`" :index="index">
                <img :class="['slide-img', `slide${index}`]" :src="item.img" alt="" />
                <div class="desc">
                    <div>{{ item.title }}</div>
                    <div class="desc-sub" :class="[currentLang]">{{ item.desc }}</div>
                </div>
                <img class="number" v-if="item.number" :src="item.number" />
                <img class="shadow" :src="require('@assets/images/home/swiper-shadow.png')" alt="" />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev">
                <span class="icon">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg"><path d="M6.157 11.657L.5 6 6.157.343" stroke="#000" stroke-width=".8" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" /></svg>
                </span>
            </div>
            <div class="swiper-button-next" slot="button-next">
                <span class="icon">
                    <svg width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg"><path d="M.843 11.657L6.5 6 .843.343" stroke="#000" stroke-width=".8" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" /></svg>
                </span>
            </div>
            <div class="mask mask-left"></div>
            <div class="mask mask-right"></div>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
export default {
    components: {
        swiper,
        swiperSlide,
    },
    computed: {
        ...mapState({
            currentLang: state => state.route.params.lang || 'zh',
        }),
        i18nPage() {
            return this.$t('aboutusInfoPage.patent');
        },
        lists() {
            return [
                {
                    number: require(`@assets/images/home/num1.png`),
                    img: require(`@assets/images/home/patent1.png`),
                    ...this.i18nPage.lists[0],
                },
                {
                    number: require(`@assets/images/home/num1.png`),
                    img: require(`@assets/images/home/patent2.png`),
                    ...this.i18nPage.lists[1],
                },
                {
                    number: require(`@assets/images/home/num1.png`),
                    img: require(`@assets/images/home/patent3.png`),
                    ...this.i18nPage.lists[2],
                },
                {
                    number: require(`@assets/images/home/num1.png`),
                    img: require(`@assets/images/home/patent4.png`),
                    ...this.i18nPage.lists[3],
                },
                {
                    number: require(`@assets/images/home/num3.png`),
                    img: require(`@assets/images/home/patent5.png`),
                    ...this.i18nPage.lists[4],
                },
            ];
        },
    },
    watch: {
        currentLang() {
            // 重复的swiper语言没切换过来
            this.showSwiper = false;
            this.$nextTick(() => {
                this.showSwiper = true;
            });
        },
    },
    data() {
        return {
            showSwiper: true,
            swiperOption: {
                initialSlide: 2,
                // updateOnWindowResize: true,
                // slideToClickedSlide: true,
                watchSlidesProgress: true,
                // loop: true,
                // loopedSlides: 6,
                slidesPerView: 'auto',
                spaceBetween: 0,
                centeredSlides: true,
                autoplay: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    1400: {
                        slidesPerView: 'auto',
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 1.75,
                    },
                },
                on: {
                    progress: function() {
                        for (var i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i);
                            var slideProgress = this.slides[i].progress;
                            var preset = [0, 0, 64, 192];
                            var scale = 1 - Math.abs(slideProgress) / 5;
                            scale = scale < 0 ? 0 : scale;
                            var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));

                            if (Math.abs(slideProgress) < 3) {
                                slide.css('zIndex', zIndex);
                                slide.css('opacity', 1);
                                slide.transform('translateX(' + preset[Math.abs(parseInt(slideProgress))] * (slideProgress > 0 ? 1 : -1) + 'px) scale(' + scale + ')');
                            } else {
                                slide.css('opacity', 0);
                            }
                        }
                    },
                    setTransition: function(transition) {
                        for (var i = 0; i < this.slides.length; i++) {
                            var slide = this.slides.eq(i);
                            slide.transition(transition);
                        }
                    },
                },
            },
        };
    },
    mounted() {
        this.mxAnimation({
            handle: '.mt-patent-ani',
            triggerElement: '.mt-patent',
        });
    },
};
</script>

<style lang="scss">
.mt-patent {
    background: #fff;

    &-swiper {
        max-width: 1180px;
        margin: 0 auto;

        .swiper-slide {
            width: 320px;
            position: relative;

            .slide-img {
                position: relative;
                z-index: 2;
                display: block;
                width: 100%;
                opacity: 0.8;
                box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.17);

                &.slide1,
                &.slide2,
                &.slide3,
                &.slide4 {
                    border-radius: 12px;
                }
            }

            .desc {
                position: absolute;
                text-align: center;
                font-weight: 600;
                font-size: 22px;
                color: #000000;
                line-height: 24px;
                opacity: 0;
                transition: all 0.6s;
                width: 100%;
                padding-top: 30px;
            }

            .number {
                position: absolute;
                top: -20px;
                right: -20px;
                opacity: 0;
                transform: scale(0);
                z-index: 3;
            }

            .shadow {
                opacity: 0;
                position: absolute;
                width: 120%;
                left: -12%;
                bottom: 0;
                z-index: 1;
                // transition: all 0.4s;
            }

            &-active {
                .title,
                .desc {
                    opacity: 1;
                }
                .slide-img {
                    opacity: 1;
                }

                .number {
                    animation: bounce_scale_1 1.2s ease-out;
                    animation-fill-mode: forwards;
                    @keyframes bounce_scale_1 {
                        0% {
                            opacity: 0;
                            transform: scale(0);
                        }
                        25% {
                            opacity: 1;
                            transform: scale(1);
                        }
                        40% {
                            transform: scale(0.9);
                        }
                        55% {
                            transform: scale(1);
                        }
                        70% {
                            transform: scale(0.98);
                        }
                        85%,
                        100% {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
                .shadow {
                    opacity: 1;
                }
                .slide-img {
                    box-shadow: none;
                }
            }
        }

        .swiper-button {
            &-prev,
            &-next {
                width: 140px;
                height: 100%;
                top: 0;
                position: absolute;
                outline: none;
                pointer-events: none;

                .icon {
                    width: 26px;
                    height: 26px;
                    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.08);
                    background: #ffffff;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 40%;
                    pointer-events: auto;
                }
            }
            &-prev {
                left: 0;
                background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);

                .icon {
                    left: 30px;
                }
            }

            &-next {
                right: 0;
                background: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%);

                .icon {
                    right: 30px;
                }
            }
        }

        .mask {
            width: 140px;
            height: 100%;
            background-image: linear-gradient(270deg, rgba(249, 249, 249, 0) 0%, #f9f9f9 100%);
        }
    }

    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        &-swiper {
            padding-top: 40px;
            padding-bottom: 240px;

            .swiper-pagination-bullets {
                bottom: 80px;
            }

            .swiper-pagination-bullet {
                width: 8px;
                height: 8px;
                background: #d9d9d9;
                margin: 0 5px !important;
                opacity: 1;

                &-active {
                    background: #000;
                    transform: scale(1.1);
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-top: 0.6rem;
        &-swiper {
            padding-top: 26px;
            padding-bottom: 1.5rem;

            .swiper-pagination-bullets {
                bottom: 0.6rem;
                font-size: 0;
            }

            .swiper-pagination-bullet {
                width: 6px;
                height: 6px;
                background: #e6e6e6;
                margin: 0 7px !important;
                opacity: 1;

                &-active {
                    background: #000;
                    transform: scale(1.1);
                }
            }

            .swiper-slide {
                .desc {
                    font-size: 12px;
                    line-height: 1.1;

                    &-sub {
                        font-size: 12px;
                    }
                }
                .shadow {
                    width: 120%;
                    left: -12%;
                }
            }
        }

        &-title {
            font-size: 0.28rem;
            line-height: 0.32rem;
            font-weight: 600;
            text-align: center;
            padding: 0 0.37rem;
        }
        &-desc {
            font-size: 0.13rem;
            line-height: 0.18rem;
            text-align: center;
            padding: 0 0.37rem;
            margin: 0.05rem 0 0.3rem 0;
        }
    }
}
</style>
