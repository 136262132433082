<template>
    <div class="aboutus-info-top">
        <div class="aboutus-info-top-container">
            <div class="aboutus-info-top-info">
                <img :src="logo" alt="" class="logo" />
                <div class="title">{{ i18nPage.title }}</div>
            </div>
            <img :src="img" class="aboutus-info-top-img" />
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('aboutusInfoPage.top');
        },
        logo() {
            let { currentLang } = this;
            if (currentLang === 'zh') {
                return require('@assets/svg/zh/logo_black.svg');
            } else if (currentLang === 'tw') {
                return require('@assets/svg/tw/logo_black.svg');
            } else {
                return require('@assets/svg/en/logo_black.svg');
            }
        },
        img() {
            let { currentLang } = this;
            const buildForOverseas = process.env.VUE_APP_BUILD_FOR === 'evelabinsight';
            if (currentLang === 'zh') {
                return require('@assets/images/aboutus/pad_zh.png');
            } else {
                return require(`@assets/images/aboutus/${buildForOverseas ? 'evelabinsight/' : ''}pad.png`);
            }
        },
    },
};
</script>

<style lang="scss">
.aboutus-info-top {
    @media only screen and (min-width: 769px) {
        height: 637px;
        margin: 0 auto;
        background: url(~@assets/images/aboutus/top.jpg) center no-repeat;
        background-size: cover;

        &-container {
            max-width: 1108px;
            margin: 0 auto;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 20px;
        }
        &-info {
            width: 480px;
            font-size: 16px;
            line-height: 20px;

            .logo {
                display: block;
                height: 50px;
                margin-bottom: 36px;
            }
        }
        &-img {
            width: 415px;
            display: block;
            margin-left: auto;
            margin-top: 60px;
            margin-right: 30px;
        }
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        height: 5.91rem;
        background: url(~@assets/images/aboutus/mtop.jpg) 0 100% no-repeat;
        background-size: cover;

        &-container {
            display: flex;
            flex-direction: column;
            padding-top: 0.92rem;
        }

        &-img {
            width: 2.45rem;
            display: block;
            margin: 0.54rem auto 0.3rem auto;
        }
        &-info {
            padding: 0 0.37rem;
            .logo {
                display: block;
                height: 0.32rem;
                margin: 0 auto 0.18rem auto;
            }
            .title {
                font-size: 0.13rem;
                line-height: 0.18rem;
                text-align: center;
            }
        }
    }
}
</style>
