<template>
    <div class="aboutus-info">
        <aboutusMenu></aboutusMenu>
        <top></top>
        <custom id="aboutus-solution" :title="customI18n.title" :list="customI18n.list"></custom>
        <patent id="aboutus-tech"></patent>
        <certification id="about-certification"></certification>
    </div>
</template>

<script>
import aboutusMenu from '../components/menu.vue';
import top from './components/top.vue';
import custom from '@views/home/components/custom.vue';
import patent from './components/patent.vue';
import certification from './components/certification.vue';
export default {
    components: {
        aboutusMenu,
        top,
        custom,
        patent,
        certification,
    },
    data() {
        return {
            pageDurationID: 'Aboutus',
        };
    },
    computed: {
        customI18n() {
            return this.$t('aboutusInfoPage.custom');
        },
    },
    mounted() {
        this.$track('aboutus_imp');
        setTimeout(() => {
            let hash = location.hash || '';
            hash = hash.replace('#', '');
            let el = document.getElementById(hash);
            if (el) {
                let offsetTop = el.offsetTop;
                let header = document.getElementById('page-header');
                window.scrollTo(0, offsetTop - header.getBoundingClientRect().height);
            }
        }, 300);
    },
};
</script>
