<template>
    <div class="aboutus-menu">
        <div class="aboutus-menu-container">
            <div class="aboutus-menu-left">
                <div class="aboutus-menu-left-container" ref="scrollEl">
                    <a href="javascript:;" class="poppins aboutus-menu-item" :style="itemStyle" :class="[{ active: current === item.anchor }, `${item.anchor}-anchor`]" @click="onMenuClick(item)" v-for="(item, index) in list" :key="index">{{ item.text }}</a>
                </div>
            </div>
            <div class="aboutus-menu-right">
                <router-link :to="{ path: '/' + $route.params.lang + '/news', query: $route.query }" class="poppins aboutus-menu-item">{{ $t('news') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current: '',
            scrollLock: false,
            itemStyle: {},
        };
    },
    computed: {
        list() {
            return [
                {
                    anchor: 'aboutus-solution',
                    text: this.$t('aboutusSolution'),
                    track: 'Aboutus_solutions',
                },
                {
                    anchor: 'aboutus-tech',
                    text: this.$t('aboutusTech'),
                    track: 'Aboutus_Technology',
                },
                {
                    anchor: 'about-certification',
                    text: this.$t('aboutusCompany'),
                    track: 'Aboutus_introduction',
                },
            ];
        },
    },
    watch: {
        current(current) {
            if (!current) return;
            var winWidth = window.innerWidth;
            this.$nextTick(() => {
                if (winWidth <= 768) {
                    var scrollEl = this.$refs.scrollEl;
                    var el = document.querySelector(`.${this.current}-anchor`);
                    scrollEl.scrollTo({
                        left: el.offsetLeft - 20,
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        },
    },
    methods: {
        // 点击滚动到那个位置
        onMenuClick(data) {
            var el = document.getElementById(data.anchor);
            if (!el) return;

            this.current = data.anchor;

            var offsetTop = el.offsetTop;
            var headerHeight = document.getElementById('page-header').offsetHeight;
            var menuHeight = this.$el.offsetHeight;

            this.scrollLock = true;
            setTimeout(() => {
                this.scrollLock = false;
            }, 400);

            window.scrollTo({
                left: 0,
                top: offsetTop - headerHeight - menuHeight,
                behavior: 'smooth',
            });
        },
        onScroll() {
            if (this.scrollLock) return;
            let current = '';
            let len = this.list.length;
            var scrollTop = document.documentElement.scrollTop;
            var winHeigth = window.innerHeight;
            for (let i = 0; i < len; i++) {
                var el = document.getElementById(this.list[i].anchor);
                var offsetTop = el.offsetTop;

                if (offsetTop - scrollTop < winHeigth * 0.65) {
                    current = this.list[i].anchor;
                }
            }
            this.current = current;
        },
    },
    mounted() {
        // TODO 防抖节流
        window.addEventListener('scroll', this.onScroll);

        var winWidth = window.innerWidth;
        if (winWidth > 768) return;

        var scrollEl = this.$refs.scrollEl;
        var scrollWidth = scrollEl.offsetWidth;
        var items = [...scrollEl.querySelectorAll('.aboutus-menu-item')];

        var num = 0;

        for (var i = 0; i < items.length; i++) {
            var cur = items[i];
            var left = cur.offsetLeft;
            var width = cur.offsetWidth;
            var curStyle = window.getComputedStyle(cur, null);
            var { marginLeft } = curStyle;
            var margin = parseInt(marginLeft);

            num += 2;

            if (left + width + margin * 2 >= scrollWidth && left < scrollWidth) {
                var off = scrollWidth - left - (width + margin * 2) / 2;

                margin += off / num;
                this.itemStyle = {
                    marginLeft: margin + 'px',
                    marginRight: margin + 'px',
                };
                break;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
};
</script>

<style lang="scss">
.aboutus-menu {
    position: fixed;
    z-index: 999;
    width: 100%;
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    font-size: 12px;
    line-height: 30px;

    @media only screen and (min-width: 769px) {
        height: 46px;
        top: 58px;

        &-container {
            height: 100%;
            max-width: 1108px;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }

        &-left {
            margin: 0 auto;

            &-container {
                display: flex;
            }

            .aboutus-menu-item {
                margin: 0 64px;
            }
        }
        &-right {
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            padding-left: 10px;
        }
        &-item {
            color: #999;
            position: relative;
            &.active {
                color: #000;
                font-weight: 500;

                &:after {
                    content: '';
                    width: 110%;
                    height: 2px;
                    background: #00c8c8;
                    left: 0;
                    bottom: -8px;
                    position: absolute;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        height: 42px;
        top: 48px;

        &-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }
        &-left {
            flex: 1;
            overflow: hidden;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 6px;
                width: 18px;
                height: 30px;
                background: linear-gradient(to right, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.9));
                pointer-events: none;
            }
            &-container {
                overflow: auto;
                position: relative;
                display: flex;
                padding: 6px 0;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
                .aboutus-menu-item {
                    margin: 0 20px;
                    font-size: 12px;
                    line-height: 30px;

                    &.active {
                        color: #00c8c8;
                        font-weight: 500;
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            width: 110%;
                            height: 2px;
                            left: -5%;
                            bottom: -6px;
                            background: #00c8c8;
                        }
                    }
                }
            }
        }
        &-right {
            flex-shrink: 0;
            padding-left: 0.1rem;
            padding-right: 0.2rem;
            position: relative;

            &:before {
                content: '';
                width: 1px;
                height: 24px;
                background: rgba(153, 153, 153, 0.3);
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
        &-item {
            color: #999;
        }
    }
}
</style>
